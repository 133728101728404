import { Divider } from 'antd';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel' 


class final extends React.Component{
    render(){
        return(
           <div> 
            {/* <div id="preloader"></div> */}
    <nav className="menu-classNameic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
         style={{backgroundColor: "#2d4143"}}>
        <div className="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img className="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
        

            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px", marginRight: "80px"  }}/>
            </a>
        </div>
            <ul style={{marginRight:"150px"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li>
                        <a href="Careers" style={{fontSize: "12px"}}>Careers</a>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    <li>                 
                        <a href="Testi" style={{fontSize: "12px"}}>Testinomials</a>                     
                    </li>
                </ul>
            </div>
        </div>
    
    </nav>
    {/* <div>
        <img src="./Content/media/services_eyecatcher.jpg" alt=""  />
        <h1 style={{maxWidth: "1200px",color: "white"}}>WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.</h1>

    </div> */}
    
    {/* <header class="header-base" style={{backgroundImage: "./Content/media/services_eyecatcher.jpg"}}>
        <div class="container">
            <h1 style={{maxWidth: "1200px",color: "white"}}>WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.</h1>
           
        </div>
    </header> */}
    {/* <div className="header">
    <img src={"Content/media/services_eyecatcher.jpg"} alt="" />  
  </div> */}


	 {/* <div class="gfg" style={{ zIndex: "-1", position: "absolute" }}>
         <div>
		<img src="Content/media/services_eyecatcher.jpg" style={{height:"auto"}}/>
		<h1 style= {{position: "absolute",top: "200px" ,left: "0", width: "100%", textAlign:"center", color:"white"}}>
        WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.
		</h1>
	</div> 
    </div> */}

 <div class="header header-base" style={{ zIndex: "-1", position: "absolute" , backgroundColor:"white"}} >
                    <div style={{position:"center"}}>
                        <img src="Content/media/services_eyecatcher.jpg" style={{width:"1700px", height:"300px"}} />
                        <h1 class="first-txt" style={{ fontWeight:"bold", color: "white", position: "absolute", top: "170px" , fontSize:"32px",width:"1000px",marginLeft:"330px",fontFamily:"Montserrat, sens sarif"}}>
                            WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES 
                            THAT POWER US
                            MORTGAGE
                            SERVICING.
                        </h1>
                        
                        

                        


            <div className="container" style={{paddingTop: "60px", paddingBottom: "60px"}}>
                <ul className="slider controls-bottom-right">
                    <div>
                        <img src="./Content/media/Services_eyecatcher.png" alt="" style={{width:"1000px", height:"400px",marginLeft:"110px",marginBottom:"30px"}}/>
                    </div>

                </ul>
                
                {/* <hr className="space" /> */}
                <div className="row">
                    <div className="col-lg-6" >
                        
                        
                    </div>
                    <div className="col-lg-6">
                        
                       
    <div id="row" style={{textAlign: "center",width:"400px",marginLeft:"-750px"}}>
  <img style={{verticalAlign:"middle",width:"150px",height:"200px"}} src="Content/media/Pankajnew.png" alt=""/>
  </div>
  <div>
      {/* 1st */}
  <h5 className="row" style={{textAlign:"left",marginLeft:"-450px",width:"300px",marginTop:"-220px"}}>Cheers 2222 to ESPL to completing 6th year of success!!!
              The culture environment and the team makes me crazy to work at ESPL.
               whatever I am doing over here has purpose and meaning.
              Process of recognizing efforts make ESPL different from other organizations 
              ESPL give me facility and flexibility to learn and Implement new technology
              Which help me to add values in my personal growth.</h5>
</div>
{/* 2nd */}
<div id="row" style={{textAlign: "center",width:"400px",marginLeft:"-120px",marginTop:"-290px"}}>
  <img style={{verticalAlign:"middle",width:"150px",height:"200px"}} src="Content/media/AmityaBikram.jpg" alt=""/>
  </div>
  <div>
  <h5 className="row" style={{textAlign:"left",marginLeft:"180px",width:"300px",marginTop:"-220px"}}>Cheers 2222 to ESPL to completing 6th year of success!!!
              The culture environment and the team makes me crazy to work at ESPL.
               whatever I am doing over here has purpose and meaning.
              Process of recognizing efforts make ESPL different from other organizations 
              ESPL give me facility and flexibility to learn and Implement new technology
              Which help me to add values in my personal growth.</h5>
</div>
{/* 3rd */}
<div id="row" style={{textAlign: "center",width:"400px",marginLeft:"-750px",marginTop:"50px"}}>
  <img style={{verticalAlign:"middle",width:"150px"}} src="Content/media/AmityaBikram.jpg" alt=""/>
  </div>
  <div>
  <h5 className="row" style={{textAlign:"left",marginLeft:"-450px",width:"300px",marginTop:"-220px"}}>Cheers 2222 to ESPL to completing 6th year of success!!!
              The culture environment and the team makes me crazy to work at ESPL.
               whatever I am doing over here has purpose and meaning.
              Process of recognizing efforts make ESPL different from other organizations 
              ESPL give me facility and flexibility to learn and Implement new technology
              Which help me to add values in my personal growth.</h5>
</div>
{/* 4th */}
<div id="row" style={{textAlign: "center",width:"400px",marginLeft:"-120px",marginTop:"-315px"}}>
  <img style={{verticalAlign:"middle",width:"150px"}} src="Content/media/AmityaBikram.jpg" alt=""/>
  </div>
  <div>
  <h5 className="row" style={{textAlign:"left",marginLeft:"180px",width:"300px",marginTop:"-220px"}}>Cheers 2222 to ESPL to completing 6th year of success!!!
              The culture environment and the team makes me crazy to work at ESPL.
               whatever I am doing over here has purpose and meaning.
              Process of recognizing efforts make ESPL different from other organizations 
              ESPL give me facility and flexibility to learn and Implement new technology
              Which help me to add values in my personal growth.</h5>
</div>

                    </div>
                </div>
                {/* <hr className="space" /> */}
                
            </div>
            {/* <div>
    
  <h5 className="row" style={{textAlign:"left",marginLeft:"-150px",width:"700px",marginTop:"-200px"}}>Cheers 2222 to ESPL to completing 6th year of success!!!
              The culture environment and the team makes me crazy to work at ESPL.
               whatever I am doing over here has purpose and meaning.
              Process of recognizing efforts make ESPL different from other organizations 
              ESPL give me facility and flexibility to learn and Implement new technology
              Which help me to add values in my personal growth.</h5>
</div> */}

{/* 3rd */}
{/* <div id="row" style={{textAlign: "center",width:"400px",marginLeft:"12px",marginTop:"60px"}}>
  <img style={{verticalAlign:"middle",width:"200px",height:"200px"}} src="Content/media/AmityaBikram.jpg" alt=""/>
  </div>
  <div>
  <h5 className="row" style={{textAlign:"left",marginLeft:"-400px",width:"700px",marginTop:"-200px"}}>Cheers 2222 to ESPL to completing 6th year of success!!!
              The culture environment and the team makes me crazy to work at ESPL.
               whatever I am doing over here has purpose and meaning.
              Process of recognizing efforts make ESPL different from other organizations 
              ESPL give me facility and flexibility to learn and Implement new technology
              Which help me to add values in my personal growth.</h5>
</div> */}


                {/* <hr className="space" /> */}
                
        
    <footer className="light">
        <div className="footer-bar">
            <div className="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="Contact">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
    </div>
     </div>
            </div>

        );
    }
}
export default final;