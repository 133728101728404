import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'



class Careers extends React.Component{
    render(){
        return(
            <div>
                <nav className="menu-classNameic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
         style={{backgroundColor: "#2d4143"}}>
        <div className="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img className="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
        

            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px" }}/>
            </a>
        </div>
            <ul style={{marginLeft:"auto", marginRight:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Employee Testimonial</a></li>
                            <li><a href="Careers" style={{fontSize: "12px"}}>Careers</a></li>
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                </ul>
            </div>
        </div>
    
    </nav>
        
    
    
            
            <iframe src="http://careers.entrasolution.com/" scrolling="yes" frameborder="0" width="100%" height="1100px"
                style={{marginTop: "100px"}}></iframe>
    
    <footer class="light">
        <div class="footer-bar">
            <div class="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="#">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
            </div>

        );
    }
}
export default Careers;

{/* <div className="container" style={{borderTopColor:"yellow"}}>
                
                <div className="row">
                    <div className="col-lg-7">


                    <Carousel controls={false} syle={{ width: "30%" ,marginBottom:"10px",backgroundSize:"cover",opacity:"1"}}>
                                            <Carousel.Item interval={2000}>
                                                <div style={{ width: "400px" , marginLeft:"250px",filter:"blur(2px)"}}>
                                                    <img height="200px" width="200px"
                                                        className="d-block w-100"
                                                        src="Content/media/Slider/home_slider4.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <Carousel.Caption>
                                                    <h3 style={{ fontSize: "32px", fontWeight: "bold", width: "70%", textAlign: "left", marginLeft:"50px",lineHeight:"60px",height:"160px"}}>
                                                        JOIN US AND TRANSFORM A $10 TRILLION INDUSTRY
                                                    </h3>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                            <Carousel.Item interval={2000}>
                                                <div style={{ width: "400px", marginLeft:"250px",filter:"blur(1px)" }}>
                                                    <img height="200px" width="200px"
                                                        className="d-block w-100"
                                                        src="Content/media/Slider/home_slider2.png"
                                                        alt="Second slide"
                                                    />
                                                </div>
                                                <Carousel.Caption>
                                                    <h3 style={{ fontSize: "32px", fontWeight: "bold", width: "70%", textAlign: "left", marginTop: "-20%" ,marginLeft:"50px",lineHeight:"60px",height:"160px"}}>
                                                        HELP MAKE HOME OWNERSHIP MORE SUSTAINABLE
                                                    </h3>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div style={{ width: "400px" , marginLeft:"250px",filter:"blur(1px)"}}>
                                                    <img height="200px" width="200px"
                                                        className="d-block w-100"
                                                        src="Content/media/Slider/home_slider3.png"
                                                        alt="Third slide"
                                                    />
                                                </div>
                                                <Carousel.Caption>
                                                    <h3 style={{ fontSize: "32px", fontWeight: "bold", width: "50%", textAlign: "left", marginTop: "-10%",marginLeft:"25px",lineHeight:"60px",height:"130px"}}>
                                                        LET'S MAKE IT HAPPEN 
                                                    </h3>
                                
                                                    
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        </Carousel>
                   


   
                    </div>
                    <div className="col-lg-5">
                        <div className="row">
                            <div className="col-lg-4">
                                <div class="grid-list gap-18" data-columns="1" data-columns-md="3" data-columns-sm="3"
                                    data-columns-xs="1">
                                </div>
                            </div>
                            <div className="col-lg-8" data-anima="fade-right" data-time="4000"
                                style= {{lineHeight:"34px" , fontSize: "20px"}}>
                                <p style={{width: "240px",marginLeft:"-150px"}}>
                                    The U.S. mortgage market is
                                    the second largest debt
                                    market in the world.
                                </p>
                            <p style={{width: "270px",marginLeft:"-150px"}}>
                                    We believe we can improve
                                    the user experience for
                                    millions of consumers.
                                 </p>
                                 <p style={{width: "240px", height:"200px",marginLeft:"-150px"}}>
                                    Build your career on our
                                    vision of the future
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div> */}