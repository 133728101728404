import React from 'react';
import Carousel from 'react-bootstrap/Carousel' 
import Dropdown from 'react-bootstrap/Dropdown'


function Home() {
    
        return(
            
            <div>
            <div className="col-md-12">
            {/* <div id="preloader"></div> */}
            <nav className="menu-classNameic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
         style={{backgroundColor: "#2d4143"}}>
        <div className="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img className="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
        

            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px"  }}/>
            
            </a>
            
        </div>
        <img src={"Content/media/GPTWEntra2024.png"} alt="" style={{height:"300px", position:"relative",right :"-90%",marginTop:"20px"}}/>
            <ul style={{marginLeft:"auto", marginRight:"auto", width:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Employee Testimonial</a></li>
                            <li><a onClick={(event) => {
          event.preventDefault(); 
          alert('You are about to leave this website and visit bsifinancial services careers page');
          window.location.href = 'https://bsifinancial.com/careers.html'; 
        }} style={{fontSize: "12px"}}>Careers</a></li>
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    
                </ul>
            </div>
        </div>
    
    </nav>
        </div>
    
    <div className="header-base" style={{ width: "100%", zIndex: "-1", position: "absolute" }}>
    <main>    
    
    <div className="shoe-container" >
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px", marginRight: "140px"  }}/>
            </a>
        </div>
        <section className="section-video section-home-slider light" >
         <video className='videoTag' autoPlay loop muted >
    <source src={'Content/media/video.mp4'} type='video/mp4' />
</video>  
            
<div class="container">
            
                <div className="row">
                    <div className="col-lg-6">
                        <ul className="slider" data-options="type:slider,perView:1,arrows:true,nav:true,autoplay:3500"
                            data-anima="fade-right" data-time="2000">
                            <li>
                                <h1 style={{width: "360px"}}>JOIN US AND TRANSFORM A $10 TRILLION INDUSTRY</h1>
                                <a className="img-box" href="#">
                                    <img src="Content/media/Slider/home_slider4.png" />
                                </a>
                            </li>
                            <li>
                                <h1 style={{width: "500px"}}>HELP MAKE HOME OWNERSHIP MORE SUSTAINABLE</h1>
                                <a className="img-box" href="#">
                                    <img src="Content/media/Slider/home_slider2.png" />
                                </a>
                            </li>
                            <li>
                                <h1 style={{width: "700px"}}>LET'S MAKE IT HAPPEN</h1>
                                <a className="img-box" href="#">
                                    <img src="Content/media/Slider/home_slider3.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-5">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="grid-list gap-18" data-columns="1" data-columns-md="3" data-columns-sm="3"
                                    data-columns-xs="1">
                                </div>
                            </div>
                            <div class="col-lg-8" data-anima="fade-right" data-time="4000"
                                style={{ fontSize: "20px"}}>
                                <p>
                                    The U.S. mortgage market is
                                    the second largest debt
                                    market in the world.
                                </p>
                                <p>
                                    We believe we can improve
                                    the user experience for
                                    millions of consumers.
                                </p>
                                <p>
                                    Build your career on our
                                    vision of the future
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </section>
      
        <section id="services" class="section-base section-color section-overflow-top" style={{marginBottom:"30px",marginTop:"40px"}}>
            
            <div className="container">
                <div className="grid-list" data-columns="3" data-columns-md="1">
                    <div className="grid-box" >
                        <div className="grid-item" >
                            <div className="cnt-box cnt-box-info boxed" >
                                <div className="img-box" >
                                    <img src="Content/media/Slider/home_primary.png" alt="" style={{width:"100%"}}/>
                                </div>
                                <div className="caption">
                                    <h2 style={{ background: "Transparent" }}>Primary Loan Servicing</h2>
                                    <p style={{fontWeight: ""}}>
                                        We support servicing and
                                        subservicing of investment
                                        grade loans, delivering
                                        exemplary service and
                                        technology that enables
                                        borrowers to manage their
                                        loans online.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="cnt-box cnt-box-info boxed" >
                                <div className="img-box">
                                    <img src="Content/media/Slider/home_special.png" alt="" style={{width:"100%"}}/>
                                </div>
                                <div className="caption">
                                    <h2 style={{ background: "Transparent" }}>Special Loan Servicing</h2>
                                    <p style={{fontWeight: ""}}>
                                        We apply regtech in our
                                        servicing operation, using
                                        Libretto from Bizzy Labs to
                                        scan 100% of loans in our
                                        portfolio, identifying
                                        exceptions that can spawn
                                        borrower complaints
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="cnt-box cnt-box-info boxed" >
                                <div className="img-box">
                                    <img src="Content/media/Slider/home_capital.png" alt="" style={{width:"100%"}}/>
                                </div>
                                <div className="caption">
                                    <h2 style={{ background: "Transparent" }}>Capital Markets</h2>

                                    <p style={{fontWeight: ""}}>
                                        We support the purchase and
                                        sale of Mortgage Servicing
                                        Rights, participating in Fannie
                                        Mae, Freddie Mac and Ginnie
                                        Mae’s programs.. We have
                                        more than 30 selling partners.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="aboutus" style={{backgroundColor:"white"}}>
            <div className="container" >
                <div className="row row-fit-lg" data-anima="fade-bottom" data-time="1000" style={{margin: "10px"}}>
                    <div className="col-lg-6">
                        <ul className="slider light" data-options="arrows:true,nav:false">
                            <li>
                                <img src="Content/media/Slider/home_balance.png" alt="" style={{ margintop:"80px",marginLeft:"50px"}} />
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6" >
                        <div className="title">
                            <h2 style={{color: "#2d4143", background:"Transparent",marginLeft:"30px",fontWeight:"bold"}}>We are guided by values</h2>
                            <p style={{color: "#2d4143",fontsize: "10px",marginLeft:"30px"}}>
                                About us
                                
                                <span style={{paddingleft: "60px",fontSize: "13px",marginLeft:"50px",margintop:"220px"}}>  PEOPLE ── <span
                                        style={{color: "#f2a53d"}}>PROCESS</span>
                                    ── TECHNOLOGY </span>
                            </p>
                        </div>
                        <p style={{width:"430px", marginLeft:"30px",fontFamily:"Montserrat, sens sarif",lineHeight:"30px",fontWeight:"300"}}>
                            We foster a reputation for performance by delivering
                            on expectations and doing what’s right. We seek to
                            improve by continually identifying and solving
                            problems. We win with humility and we’re frugal so we
                            can invest in our future
                        </p>
                        <p style={{width:"430px", marginLeft:"30px",fontFamily:"Montserrat, sens sarif",lineHeight:"30px",fontWeight:"300"}}>
                            These core values drive our daily operations and
                            decision-making, and our approach to transforming
                            the U.S. real estate economy using people, processes
                            and technology.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-image light" data-parallax="scroll" style={{display:'none'}}>
        </section>

        <section id="technology" class="section-base">
            <div className="container">
            
                <div className="title" data-anima="fade-bottom" data-time="1000">
                    <h2 style={{color: "#2d4143",zIndex: "100", background:"Transparent",fontWeight:"bold"}}>What we're effective</h2>
                    <p style={{color: "#2d4143",marginTop: "20pxs"}}>TECHNOLOGY</p>
                </div>
                {/* <hr className="space-xs" /> */}
                <div className="tab-box tab-top-right" data-tab-anima="fade-bottom" data-anima="fade-bottom"
                    data-time="1000" >
                    <ul className="tab-nav" style={{marginTop: "10px"}}>
                        <li><a href="#">PEOPLE</a></li>
                        <li><a href="#">PROCESS</a></li>
                        <li><a href="#" style={{color: "#f2a53d"}}>TECHNOLOGY</a></li>
                    </ul>
                    <div className="panel active">
                        <div className="grid-list" data-columns="2" data-columns-lg="2" data-columns-sm="1">
                            <div className="grid-box">
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-top boxed">
                                        <div className="caption">
                                            <div>
                                            <a href="https://bsicares.bsifinancial.com/" target="_blank">  
                                            <img src="Content/media/Slider/home_cares.png" alt=""
                                                style={{width:"100px"}} />
                                                </a>
                                                </div>
                                            <h2 style={{ background: "Transparent" }}>BSI CARES</h2>
                                            <p style={{fontWeight: "",fontFamily:"Montserrat, sens sarif"}}>
                                                Automates quality review of new loan files
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-top-icon boxed">
                                        <div class="caption">
                                            <div>
                                        <a href="https://bizzylabs.tech/" target="_blank">
                                            <img src="Content/media/Slider/home_libretto.png" alt=""
                                                style={{width:"100px"}} />
                                                </a>
                                                </div>
                                            <h2 style={{ background: "Transparent" }}>Libretto, from Bizzy Labs</h2>
                                            <p style={{fontWeight: "",fontFamily:"Montserrat, sens sarif"}}>
                                                Daily exception scoring of 100% of loans in our portfolio
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div class="cnt-box cnt-box-top-icon boxed">
                                        <div className="caption">
                                            <div>
                                        <a href="https://reports.bsifinancial.com/" target="_blank">
                                            <img src="Content/media/Slider/home_asset360.png" alt=""
                                                style={{width:"72px"}} />
                                                </a>
                                                </div>
                                            <h2 style={{ background: "Transparent" }}>BSI ASSET360</h2>
                                            <p style={{fontWeight: "",fontFamily:"Montserrat, sens sarif"}}>
                                                Provides real-time visibility into loan status
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div class="cnt-box cnt-box-top-icon boxed">
                                        <div className="caption">
                                            
                                            
                                        {/* <a href="https://myloanweb.com/bsi/login.aspx" >
                                            <img src="Content/media/Slider/home_myloanweb.png" alt=""
                                            
                                                style={{width:"100px"}} />
                                                </a> */}
                                                   <div>
                                        <a href="https://myloanweb.com/bsi/login.aspx" target="_blank">
                                            <img src="Content/media/Slider/home_myloanweb.png" alt=""
                                                style={{width:"85px"}} />
                                                </a>
                                                </div>
                                                
                                        
                                            <h2 style={{ background: "Transparent" }}>MyLoanWeb</h2>
                                            <p style={{fontWeight: "",fontFamily:"Montserrat, sens sarif"}}>
                                                Enables consumers to manage their loans online
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        <section id="leadership" class="section-base section-color">
            <div className="container">
                <div className="row" data-anima="fade-bottom" data-time="1000" >
                    <div className="col-lg-3">
                        <div className="title">
                            <h2 style={{color: "#2d4143", background:"Transparent", fontWeight:"bold"}}>What we're effective</h2>
                            <p style={{color: "#2d4143",marginTop: "10px"}}>Our Core team</p>
                        </div>
                        <p style={{fontSize:"15px",fontFamily:"Montserrat, sens sarif",lineHeight:"35px"}}>
                            Entra’s core management
                            team is developing
                            tomorrow’s leaders in all
                            phases of mortgage loan
                            processing
                        </p>
                        <p style={{ fontSize:"15px",fontFamily:"Montserrat, sens sarif",lineHeight:"35px"}}>
                        Their focus is on delivering
                            value to clients and their
                            customers, enabling
                            sustainable home
                            ownership
                        </p>
                        
                    </div>
                    <div className="col-lg-9" style={{margintop:"20px"}}>
                        <div className="title" style={{textAlign:"right"}}>
                            <p style={{color: "#2d4143",fontSize: ";",marginTop: "10px"}}>
                                <span style={{color: "#f2a53d", marginTop: "10px"}}>PEOPLE ── </span><span>PROCESS ── TECHNOLOGY </span>
                            </p>
                        </div>
                        <div className="grid-list" data-columns="4" data-columns-md="2" data-columns-sm="1">
                            <div className="grid-box">
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-team boxed">
                                        <img src="Content/media/Jayesh.jpg" alt="" />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>Jayesh Joshi</h2>
                                            <span>SVP : Head of ESPL India</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/jayeshkjoshi?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B5PRaOYdBTVyVvtBL1rJlRw%3D%3D"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-team boxed ">
                                        <img src="Content/media/Jobynew22.png" alt="" />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>Joby Varghese</h2>
                                            <span>SVP : Infra/Technology</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/joby-varghese-m-4660b219a/"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-team boxed">
                                        <img src="Content/media/KS1.png" alt="" />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>K Shyamala</h2>
                                            <span>AVP : L&D</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/k-shyamala-a33115a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bcmi26xIVTYOkbUCA5SdcVA%3D%3D"
                                                    target="_blank" rel="noreferrer" ><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-team">
                                        <img src="Content/media/ARC.png" alt="" style={{height:"220px"}}  />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent", lineHeight:"19px"}}>ARINDAM ROY CHOWDHURY</h2>
                                            <span>VP : Operations</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/arindam-roy-chowdhury-67537aa9"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-team">
                                        <img src="Content/media/tmehtahr.png" alt="" style={{height:"220px"}} />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>Taruna Mehta</h2>
                                            <span>AVP : HR & Admin</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/taruna-m-30589851?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BSXen%2FD%2F7QKes46TuOhj6kw%3D%3D"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div class="cnt-box cnt-box-team">
                                        <img src="Content/media/SSNew.jpg" alt="" style={{height:"220px"}}  />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>Shweta Sharma</h2>
                                            <span>Senior Manager : Operations</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/shweta-s-1486231a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BR2WX1W8HTeuH%2Fp0UOohXnA%3D%3D"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="cnt-box cnt-box-team">
                                        <img src="Content/media/PKumar1.png" alt=""  />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>Pankaj Kumar</h2>
                                            <span>Senior Manager : Application Development</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/pankaj-kumar-2a2b6061/"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="grid-item">
                                    <div className="cnt-box cnt-box-team">
                                        <img src="Content/media/Rakesh.png" alt="" style={{height:"220px"}}  />
                                        <div className="caption">
                                            <h2 style={{ background: "Transparent" }}>Rakesh Kumar</h2>
                                            <span>Manager : IT Support</span>
                                            <span className="icon-links">
                                                <a href="https://www.linkedin.com/in/krakesh-8377/"
                                                    target="_blank" rel="noreferrer"><img src="Content/media/logos/linkedin_logo.png"
                                                        alt="linkedin-icon"/></a>
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="affiliates" class="section-base">
            <div className="container" style={{paddingTop: "60px"}}>
                <div className="title">
                    <span style={{fontSize:"22px",paddingTop: "10px", background:"Transparent",margintop:"30px",fontFamily:"Montserrat, sens sarif",fontWeight:"bold"}}>Our Affiliates
                    </span>
                    <p style={{paddingTop: "10px",fontFamily:"Montserrat, sens sarif"}}>THE COMPANY WE KEEP</p>
                    {/* <p><br><br><br>THE COMPANY WE KEEP</br></br></br></p> */}


                    
                </div>
                <div className="col-md-12">
                <div className="album-list">
                    
                    <div className="album-box" >
                        <a href="https://www.bsifinancial.com/" target="_blank" rel="noreferrer" class="img-box"
                            style={{backgroundColor: "white", marginTop:"-22px"}}>
                            <img src="Content/media/Slider/BSIFinancial.png" alt="" />
                        </a>

                    </div>
                    
                    <div className="album-box">
                        <a href="https://bizzylabs.tech/" target="_blank" rel="noreferrer" class="img-box"
                            style={{backgroundColor: "white" }}>
                            <img src="Content/media/Slider/home_affiliates_bizzy.jpg" alt=""/>
                        </a>
                    </div>
                    <div className="album-box">
                        <a href="https://entrasolutionsllc.com/" target="_blank" rel="noreferrer" class="img-box"
                            style={{backgroundColor: "white", }}>
                            <img src="Content/media/Slider/home_affiliates_entra.jpg" alt="" />
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </section>   
    </main>
    <div >
        <a href="#top" style={{position:"sticky", scrollBehavior:"smooth"}}>
     <i className="scroll-top-btn scroll-top show" >
         </i> 
         </a>
         </div>
         <footer className="light">
        <div className="footer-bar">
            <div className="container">
                <span>© 2024 Entra Solutions Pvt Ltd | <a href="#">Contact us</a> </span>
                <span></span>
                
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>

    
    </div>
    </div>
);
    
}
export default Home;