import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

class lifeatespl extends React.Component{
    render(){
        return(
                <div>
            {/* <div id="preloader"></div> */}
    <nav className="menu-classic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
        style={{backgroundColor: "#2d4143"}}>
        <div className="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img class="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img class="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img class="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img class="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px"  }}/>
            </a>
        </div>
        <img src={"Content/media/GPTWEntra2024.png"} alt="" style={{height:"300px", position:"relative",right :"-90%",marginTop:"20px"}}/>
            <ul style={{marginLeft:"auto", marginRight:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Employee Testimonial</a></li>
                            <li><a  onClick={(event) => {
          event.preventDefault(); 
          alert('You are about to leave this website and visit bsifinancial services careers page');
          window.location.href = 'https://bsifinancial.com/careers.html'; 
        }} style={{fontSize: "12px"}}>Careers</a></li>
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    
                </ul>
            </div>
        </div>
    </nav>
    {/* <header class="header-base" img src={"Content/media/eyecatcher_life-espl.png"} alt="">
        <div class=" header">
        < img src={"Content/media/eyecatcher_life-espl.png"} alt=""/>
            <h1 style={{maxWidth: "1200px", color: "white", fontSize: "35px"}}>ENTRA SOLUTIONS OFFERS A VERY SOCIAL WORK
                ENVIRONMENT.</h1>
            
        </div> */} 
     {/* </header> */}
     {/* <div className="header">
     <img src={"Content/media/eyecatcher_life-espl.png"} alt="" />  
     <div>
        <h1>Life @ ESPL</h1>
     </div>
   </div>
    */}
    {/* <div class="header">
    <div class="container">
    <img src={"Content/media/eyecatcher_life-espl.png"} alt="" />  
    <div class="bottom-left">Life @ ESPL</div>
    </div>
    </div> */}



	{/* <div class="Image">
		<img src="Content/media/eyecatcher_life-espl.png" />  
		<h1 style= {{position: "absolute",top: "200px" ,left: "0", width: "100%",down:"20px"}}>
        ENTRA SOLUTIONS OFFERS A VERY SOCIAL WORK
                ENVIRONMENT.
		</h1>
	</div>  */}
 
 

 <div className="header header-base"  style={{ width: "100%", zIndex: "-1", position: "absolute" }}>
                    <div >
                        <img src="Content/media/eyecatcher_life-espl.png" style= {{width:"100%", height:"300px",marginBottom:"50px"}}/>
                        <h1 className="first-txt" style={{fontWeight:"bold", fontSize:"32px",  color: "white", position: "absolute", top: "160px",width:"90%",marginLeft:"9%",fontFamily:"Montserrat, sens sarif" }}>
                        ENTRA SOLUTIONS OFFERS A VERY SOCIAL WORK
                ENVIRONMENT.
                        </h1>

            <div className="container" style={{ position:"center"}}>
                <div className="album" data-album-anima="fade-bottom" data-columns-md="2" data-columns-sm="1" style={{width:"100%"}}>
                    <div className="album-list">
                        <div className="album-box">
                            <a href="# " className="img-box img-scale">
                                <img src={"Content/media/launch_thumbnail.jpg"} style ={{width:"100%"}} alt="" />
                            </a>
                            <div className="caption">
                                <h3>ESPL Launch Party</h3>
                            </div>
                        </div>
                        <div className="album-box">
                            <a href="#" className="img-box img-scale">
                                <img src={"Content/media/fnf_thumbnail.jpg"} style ={{width:"100%"}} alt=""/>
                            </a>
                            <div className="caption">
                                <h3>Fun at Floor</h3>
                            </div>
                        </div>
                        <div className="album-box">
                            <a href="#" className="img-box img-scale">
                                <img src={"Content/media/Diwali_Thumbnail.jpg"} style ={{width:"100%"}} alt=""/>
                            </a>
                            <div className="caption">
                                <h3>Virtual Diwali 2020</h3>
                            </div>
                        </div>
                        <div className="album-box">
                            <a href="#" className="img-box img-scale">
                                <img src={"Content/media/rnr1_thumbnail.jpg"} style ={{width:"100%"}} alt=""/>
                            </a>
                            <div className="caption">
                                <h3>R&R/Annual Day-2019</h3>
                            </div>
                        </div>
                        <div className="album-box">
                            <a href="#" className="img-box img-scale">
                                <img src={"Content/media/rnr2_thumbnail.jpeg"} style ={{width:"100%"}} alt=""/>
                            </a>
                            <div className="caption"> 
                                <h3>R&R/Annual Day-2018</h3>
                            </div>
                        </div>
                        <div className="album-box">
                            <a href="#" className="img-box img-scale">
                                <img src={"Content/media/rnr3_thumbnail.jpeg"} style ={{width:"100%"}} alt=""/>
                            </a>
                            <div className="caption">
                                <h3>R&R/Annual Day-2017</h3>
                            </div>
                        </div>
                    </div>
                    <div className="cnt-album-box">
                        <p className="album-title"><span>...</span> <a>Album list</a></p>
                        <div className="album-item">
                            <div className="grid-list list-gallery" data-lightbox-anima="fade-top" data-columns="3"
                                data-columns-md="2">
                                <div className="grid-box">
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launch1.jpg"}>
                                            <img src={"Content/media/launch1.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launch2.jpg"}>
                                            <img src={"Content/media/launch2.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launch3.jpg"}>
                                            <img src={"Content/media/launch3.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launch4.jpg"}>
                                            <img src={"Content/media/launch4.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launc5.jpg"}>
                                            <img src={"Content/media/launch5.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launch6.jpg"}>
                                            <img src={"Content/media/launch6.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/launch7.jpg"}>
                                            <img src={"Content/media/launch7.jpg"} alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="list-pagination">
                                    <ul className="pagination" data-page-items="6" data-pagination-anima="fade-right"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="album-item">
                            <div className="grid-list list-gallery" data-lightbox-anima="fade-top" data-columns="3"
                                data-columns-md="2">
                                <div className="grid-box">
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf1.jpg"}>
                                            <img src={"Content/media/fnf1.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf2.jpg"}>
                                            <img src={"Content/media/fnf2.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf3.jpg"}>
                                            <img src={"Content/media/fnf3.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf4.jpg"}>
                                            <img src={"Content/media/fnf4.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf5.jpg"}>
                                            <img src={"Content/media/fnf5.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf6.jpg"}>
                                            <img src={"Content/media/fnf6.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf7.jpg"}>
                                            <img src={"Content/media/fnf7.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/fnf8.jpg"}>
                                            <img src={"Content/media/fnf8.jpg"} alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="list-pagination">
                                    <ul className="pagination" data-page-items="6" data-pagination-anima="fade-right"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="album-item">
                            <div className="grid-list list-gallery" data-lightbox-anima="fade-top" data-columns="3"
                                data-columns-md="2">
                                <div className="grid-box">
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/Diwali1.jpg"}>
                                            <img src={"Content/media/Diwali1.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/Diwali2.jpg"}>
                                            <img src={"Content/media/Diwali2.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/Diwali3.jpg"}>
                                            <img src={"Content/media/Diwali3.jpg"} alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="list-pagination">
                                    <ul className="pagination" data-page-items="6" data-pagination-anima="fade-right"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="album-item">
                            <div className="grid-list list-gallery" data-lightbox-anima="fade-top" data-columns="3"
                                data-columns-md="2">
                                <div className="grid-box">
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr1.jpg"}>
                                            <img src={"Content/media/rnr1.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr2.jpg"}>
                                            <img src={"Content/media/rnr2.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr3.jpg"}>
                                            <img src={"Content/media/rnr3.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr4.jpg"}>
                                            <img src={"Content/media/rnr4.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr5.jpg"}>
                                            <img src={"Content/media/rnr5.jpg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr6.jpg"}>
                                            <img src={"Content/media/rnr6.jpg"} alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="list-pagination">
                                    <ul className="pagination" data-page-items="6" data-pagination-anima="fade-right"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="album-item">
                            <div className="grid-list list-gallery" data-lightbox-anima="fade-top" data-columns="3"
                                data-columns-md="2">
                                <div className="grid-box">
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-a.jpeg"}>
                                            <img src={"Content/media/rnr-a.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-b.jpeg"}>
                                            <img src={"Content/media/rnr-b.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-c.jpeg"}>
                                            <img src={"Content/media/rnr-c.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                   
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-e.jpeg"}>
                                            <img src="Content/media/rnr-e.jpeg" alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-f.jpeg"}>
                                            <img src={"Content/media/rnr-f.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                    
                                </div>
                                <div className="list-pagination">
                                    <ul className="pagination" data-page-items="6" data-pagination-anima="fade-right"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="album-item">
                            <div className="grid-list list-gallery" data-lightbox-anima="fade-top" data-columns="3"
                                data-columns-md="2">
                                <div className="grid-box">
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-r.jpeg"}>
                                            <img src={"Content/media/rnr-r.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-x.jpeg"}>
                                            <img src={"Content/media/rnr-x.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-y.jpeg"}>
                                            <img src={"Content/media/rnr-y.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                    <div className="grid-item">
                                        <a className="img-box" href={"Content/media/rnr-z.jpeg"}>
                                            <img src={"Content/media/rnr-z.jpeg"} alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="list-pagination">
                                    <ul className="pagination" data-page-items="6" data-pagination-anima="fade-right"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       

    <footer className="light">
        <div className="footer-bar" style={{marginTop:"100px"}}>
            <div className="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="Contact">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
    </div>
        </div>
    </div>
            
            
            
        );
    }
}
export default lifeatespl;