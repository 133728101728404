import { Divider } from 'antd';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel' 
import Dropdown from 'react-bootstrap/Dropdown'
// import Link from 'react-bootstrap/Link';





class Testi extends React.Component{
    render(){
        return(
           <div> 
            {/* <div id="preloader"></div> */}
    <nav className="menu-classNameic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
         style={{backgroundColor: "#2d4143"}}>
        <div className="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img className="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
        

            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px"}}/>
            </a>
        </div>
        <img src={"Content/media/GPTWEntra2024.png"} alt="" style={{height:"300px", position:"relative",right :"-90%",marginTop:"20px"}}/>
            <ul style={{marginLeft:"auto", marginRight:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Employee Testimonial</a></li>
                            <li><a  onClick={(event) => {
          event.preventDefault(); 
          alert('You are about to leave this website and visit bsifinancial services careers page');
          window.location.href = 'https://bsifinancial.com/careers.html'; 
        }} style={{fontSize: "12px"}}>Careers</a></li>
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    
                </ul>
            </div>
        </div>
    
    </nav>
    {/* <div>
        <img src="./Content/media/services_eyecatcher.jpg" alt=""  />
        <h1 style={{maxWidth: "1200px",color: "white"}}>WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.</h1>

    </div> */}
    
    {/* <header class="header-base" style={{backgroundImage: "./Content/media/services_eyecatcher.jpg"}}>
        <div class="container">
            <h1 style={{maxWidth: "1200px",color: "white"}}>WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.</h1>
           
        </div>
    </header> */}
    {/* <div className="header">
    <img src={"Content/media/services_eyecatcher.jpg"} alt="" />  
  </div> */}


	 {/* <div class="gfg" style={{ zIndex: "-1", position: "absolute" }}>
         <div>
		<img src="Content/media/services_eyecatcher.jpg" style={{height:"auto"}}/>
		<h1 style= {{position: "absolute",top: "200px" ,left: "0", width: "100%", textAlign:"center", color:"white"}}>
        WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.
		</h1>
	</div> 
    </div> */}

 <div class="header header-base" style={{ zIndex: "-1", position: "absolute" , backgroundColor:"white"}} >
                    <div style={{position:"center"}}>
                        <img src="Content/media/career_eyecatcher.png" style={{width:"1900px", height:"300px"}} />
                        <h1 class="first-txt" style={{ fontWeight:"bold", color: "white", position: "absolute", top: "170px" , fontSize:"32px",width:"90%",marginLeft:"9%",fontFamily:"Montserrat, sens sarif"}}>
                            
                           EMPLOYEE TESTIMONIAL
                        </h1>
                        
              <div className="container" style={{paddingTop: "60px", paddingBottom: "60px"}}>
                
                
               
                   





              
                
         <div className="col-md-12">          
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black",marginBottom:"56px",padding:"25px"}}>
      <div className="col-md-3">
  <img src="Content/media/PKumar.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  I have been associated with ESPL from more than 6 years now, I testify that this company has a great culture, work always gets recognized and rewarded. 
  ESPL offers everyone the opportunity to foster and grow both professionally and personally. 
  I have not only learnt a lot over these years about my own domain, but there has also been a lot of cross functional learning
  Peers at ESPL are extremely encouraging, talented and like one big family who are always around to support at times of need. 
  With offices across the USA we get to interact with colleagues from various cultural background, which is also another interesting facet of working for ESPL.
   And it’s not work all the time,
   ESPL also has a proactive approach to employee wellbeing and frequently organizes events, sports. ESPL has been one of the best places to work
  <div style={{textAlign:"right",fontWeight:"bold"}}> <span>Pankaj Kumar</span><br/><span>Manager - Application Management</span> </div> 
  {/* <div style={{marginLeft:"550px",fontWeight:"bold"}}>Manager - Application Management</div>               */}
</div> 
</div>
       
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom:"56px",padding:"25px",height:"390px"}}>

     
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
 
  I am grateful to work for Entra Solutions which gave me various opportunities to learn and grow everyday. 
  One of the best thing is the gender equality and promoting the women workforce at workplace. 
  Safe work environment and flexibility given to work from home during pandemic shows the trust in employees. 
  Various fortnightly employee engagement activities help existing employees feel more connected to their organization in virtual environment. 
  Plus, it shows that Entra Solutions is a fun, fintech company to work for and an organization that values and empowers its employees. 

    <div style={{textAlign:"left",fontWeight:"bold"}}> <span>Shweta Sharma</span><br/><span>Senior Manager - Operations</span> </div> 
    {/* <div style={{marginLeft:"8px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Shweta Sharma</div>  */}
  {/* <div style={{marginLeft:"5px",fontWeight:"bold",marginTop:"5px",fontSize:"20px"}}> Senior Manager - Operations</div>          */}
</div> 
 
<div className="col-md-3">
  <img src="Content/media/ShwetaUp.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
   
</div>
           
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px",padding:"25px"}}>

      <div className="col-md-3">
  <img src="Content/media/Abikram11.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  Cheers to ESPL on completing 6th year of success !!!
The culture, environment and the team makes me crazy to work at ESPL. Whatever I am doing over here has purpose and meaning. 
Process of recognizing efforts makes ESPL different from other organizations. 
ESPL gives me facility and flexibility to learn and implement new technology which help me to add values in my personal growth. 
I love the kind of brainstorming sessions I have with my team. They are full of energy and helps one in learning many new things. 
Something I really appreciate ESPL for are the colleagues I got here. 

              <div style={{textAlign:"right",fontWeight:"bold"}}> <span>Amitya Bikram Sarangi</span><br/><span>Senior Software Engineer</span> </div>    
              {/* <div style={{marginLeft:"600px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Amitya Bikram Sarangi</div>  */}
  {/* <div style={{marginLeft:"600px",fontWeight:"bold",marginTop:"5px",fontSize:"20px"}}> Senior Software Engineer</div>   */}
 
</div> 

</div>
         
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px" ,padding:"25px",height:"400px"}}>

     
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
  ESPL provides learning and growth opportunities to its employees for by conducting several behavioral and domain trainings.
The company also takes care of the mental health of it's employees through seminar or session on mental health. 
A chance to get trained in various processes is given to all employees which helps in enhancing ones  knowledge.
I have a sense of job security and I am glad that I am a part of such an  amazing organization.

      <div style={{textAlign:"left",fontWeight:"bold",marginTop:"10px"}}> <span>Aditi Patni</span><br/><span>Subject Matter Expert</span> </div>  
      {/* <div style={{marginLeft:"-1px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Aditi Patni</div>  */}
  {/* <div style={{marginLeft:"-5px",fontWeight:"bold",marginTop:"10px",fontSize:"20px"}}> Subject Matter Expert</div>       */}
 
</div> 
<div className="col-md-3">
  <img src="Content/media/AditiP.png" style={{width:"98%", height:"55%"}} alt=""/>
  </div>
    
</div>
     
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px",padding:"25px"}}>

      <div className="col-md-3">
  <img src="Content/media/GovindS.png"style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
     More than 3 years at ESPL! 
     My journey has been filled with learning and growing within the organization. I am fortunate to be surronded with great 
     indivisuals who are generous in sharing knowledge. I am greateful to have been able to work in a place which 
     makes one grow and achieve greater heights.
     Proud to be a part of ESPL Family.  
     <div style={{textAlign:"right",fontWeight:"bold",marginTop:"10px"}}> <span>Govind Surya</span><br/><span> Process Lead</span> </div>  
     {/* <div style={{marginLeft:"700px",fontWeight:"bold",marginTop:"40px",fontSize:"20px",align:"left"}}> Govind Surya</div>  */}
  {/* <div style={{marginLeft:"700px",fontWeight:"bold",marginTop:"10px",fontSize:"20px",align:"left"}}> Process Lead</div>         */}
 
</div>

</div>
            
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px",padding:"25px",height:"400px"}}>

      
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
  I am glad to be working at Entra Solutions because this is the company that truly values its employees and their work.
   ESPL provided me a platform to learn and upskill myself.
    Working here gives immense pleasure because of its work culture and employee friendly atmosphere.
My team is truly inspiring & I am valued and trusted at ESPL 
     <div style={{textAlign:"left",fontWeight:"bold",marginTop:"10px"}}> <span>Sonam Bhutia</span><br/><span>Senior Process Associate</span> </div>  
     {/* <div style={{marginLeft:"5px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Sonam Bhutia</div>  */}
  {/* <div style={{marginLeft:"5px",fontWeight:"bold",marginTop:"10px",fontSize:"20px"}}>Senior Process Associate</div>   */}
 
</div> 
<div className="col-md-3" >
  <img src="Content/media/SonamB.png"style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  
</div>
           
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px" ,padding:"25px",height:"370px"}}>

      <div className="col-md-3">
  <img src="Content/media/AbhayK.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
  I love my role in ESPL because of the ample growth opportunities that I have got in the form of 
  training and learning sessions and I look forward to more of these. It is a combination of both 
  professional and friendly environment that one gets to work in at ESPL. Life is in total harmony because of the work life balance here.

     <div style={{textAlign:"right",fontWeight:"bold",marginTop:"10px"}}> <span>Abhay Kumar</span><br/><span>Specialist</span> </div>     
     {/* <div style={{marginLeft:"700px",fontWeight:"bold",marginTop:"40px",fontSize:"20px",align:"left"}}>Abhay Kumar</div>  */}
  {/* <div style={{marginLeft:"703px",fontWeight:"bold",marginTop:"10px",fontSize:"20px",align:"left"}}>Specialist</div>   */}
</div> 

</div>
       
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px",padding:"25px",height:"370px"}}>

     
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
  I appreciate the company’s priorities, insights, and culture. 
  I joined as an SPA and from the start have been impressed how everyone works with a goal-oriented approach. 
  ESPL is a company that operates with a positive attitude and has its primacies right. Respect,
   care, excellence are qualities that exude from the top and flow throughout the company.  
ESPL is an exceptional company and a  great place to work. I am excited for the growth of this company.
  
    <div style={{textAlign:"left",fontWeight:"bold",marginTop:"10px"}}> <span>Priya Kumari</span><br/><span>Senior Process Associate</span> </div>   
    {/* <div style={{marginLeft:"5px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Priya Kumari</div>  */}
  {/* <div style={{marginLeft:"5px",fontWeight:"bold",marginTop:"10px",fontSize:"20px"}}>Senior Process Associate</div>   */}
</div> 
<div className="col-md-3">
  <img src="Content/media/PriyaK.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  
</div>
             
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px",padding:"25px"}}>

      <div className="col-md-3">
  <img src="Content/media/RichaS.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
  I joined Entra in the year 2019 and it’s been a year and a half now with ESPL family. 
  The team and its diversity makes every working day a pleasure. 
  ESPL values its employees by providing a peaceful and healthy working atmosphere.
   We have a great work culture at Entra and a perfect work life balance. 
I am glad to be a part of ESPL where I can be ‘Best of me’.

     <div style={{textAlign:"right",fontWeight:"bold",marginTop:"10px"}}> <span>Richa Sharma</span><br/><span>Analyst</span> </div> 
     {/* <div style={{marginLeft:"700px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Richa Sharma</div>  */}
  {/* <div style={{marginLeft:"702px",fontWeight:"bold",marginTop:"10px",fontSize:"20px"}}>Analyst</div> */}
</div> 
  
</div>
        
    {/* <div id="row" style={{textAlign: "center"}}> */}
    <div className = "row" style={{boxShadow: "0px 0px 23px black", marginBottom: "56px",padding:"25px",height:"400px"}}>

     
  <div className="col-md-9" style={{marginTop:"18px", fontSize:"20px"}}>
  
  One of the things that matter most to me is to work under the guidance of a leaders who want to make leaders out of the subordinate. 
  The managers who do not think of the people as some machines but humans with their own mind- that’s what I have gotten here at ESPL.
   I am grateful for the cooperation that I continue to receive every single day from my team members!

     <div style={{textAlign:"left",fontWeight:"bold",marginTop:"10px"}}> <span>Priyanka</span><br/><span>Trainee Process Associate</span> </div>      
     {/* <div style={{marginLeft:"7px",fontWeight:"bold",marginTop:"40px",fontSize:"20px"}}>Priyanka</div>  */}
  {/* <div style={{marginLeft:"5px",fontWeight:"bold",marginTop:"10px",fontSize:"20px"}}>Trainee Process Associate</div>  */}
</div> 
<div className="col-md-3">
  <img src="Content/media/PriyankaT.png" style={{width:"98%", height:"81%"}} alt=""/>
  </div>
   
</div>
   </div>





                </div>
                {/* <hr className="space" /> */}
                
            </div>
            <div >
        <a href="#top" style={{position:"sticky", scrollBehavior:"smooth"}}>
     <i className="scroll-top-btn scroll-top show" >
         </i> 
         </a>
         </div>  
        
    <footer className="light">
        <div className="footer-bar">
            <div className="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="Contact">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
    </div>
     
            </div>

        );
    }
}
export default Testi;