// import logo from './Content/media/logos/ESPL_White_Logo.png';
import React from 'react';
import Home from './pages/home';
import Contact from './pages/Contact';
import CoreValues from './pages/CoreValues';
import LifeAtEspl from './pages/LifeAtEspl';
import Services from './pages/Services';
import Careers from './pages/Careers';
import Test from './pages/Test';
import final from './pages/final';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';



function App() {
  return (
    <div>
    <Router>
    <Route path='/' exact component={Home}/> 
      <switch>
        <Route path='/home' component={Home}/>  
      <Route path='/Contact' component={Contact}/>
      <Route path='/final' component={final}/>
      <Route path='/Test' component={Test}/>
      <Route path='/CoreValues' component={CoreValues}/>
      <Route path='/LifeAtEspl' component={LifeAtEspl}/>
      <Route path='/Services' component={Services}/>
      <Route path='/Careers' component={Careers}/>
  
      </switch>
    
    </Router>
    </div>
  );
}

export default App;
{/* <Contact />
    <Services />
    <LifeAtEspl />
    <Careers />
    <CoreValues /> */}
