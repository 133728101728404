
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

import Carousel from 'react-bootstrap/Carousel' 


class Services extends React.Component{
    render(){
        return(
           <div> 
               
            {/* <div id="preloader"></div> */}
    <nav className="menu-classNameic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
         style={{backgroundColor: "#2d4143"}}>
        <div className="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img className="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
        

            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px" }}/>
            </a>
        </div>
        <img src={"Content/media/GPTWEntra2024.png"} alt="" style={{height:"300px", position:"relative",right :"-90%",marginTop:"20px"}}/>
            <ul style={{marginLeft:"auto", marginRight:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Testimonial</a></li>
                            <li><a  onClick={(event) => {
          event.preventDefault(); 
          alert('You are about to leave this website and visit bsifinancial services careers page');
          window.location.href = 'https://bsifinancial.com/careers.html'; 
        }}style={{fontSize: "12px"}}>Careers</a></li>
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    
                    
                </ul>
            </div>
        </div>
    
    </nav>
    {/* <div>
        <img src="./Content/media/services_eyecatcher.jpg" alt=""  />
        <h1 style={{maxWidth: "1200px",color: "white"}}>WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.</h1>

    </div> */}
    
    {/* <header class="header-base" style={{backgroundImage: "./Content/media/services_eyecatcher.jpg"}}>
        <div class="container">
            <h1 style={{maxWidth: "1200px",color: "white"}}>WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.</h1>
           
        </div>
    </header> */}
    {/* <div className="header">
    <img src={"Content/media/services_eyecatcher.jpg"} alt="" />  
  </div> */}


	 {/* <div class="gfg" style={{ zIndex: "-1", position: "absolute" }}>
         <div>
		<img src="Content/media/services_eyecatcher.jpg" style={{height:"auto"}}/>
		<h1 style= {{position: "absolute",top: "200px" ,left: "0", width: "100%", textAlign:"center", color:"white"}}>
        WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES THAT POWER US
                MORTGAGE
                SERVICING.
		</h1>
	</div> 
    </div> */}

 <div class="header header-base"  style={{ width: "100%", zIndex: "-1", position: "absolute" }}>
            <div style={{position:"center"}}>
                        <img src="Content/media/services_eyecatcher.jpg" style={{width:"100%",height:"300px"}}/>
                        <h1 class="first-txt" style={{ fontWeight:"bold", color: "white", position: "absolute", top: "170px" ,marginLeft:"13%", fontSize:"32px",width:"110%",fontFamily:"Montserrat, sens sarif"}}>
                            WE ARE A PREMIER PROVIDER OF COMPONENT SERVICES 
                            THAT POWER US   
                                 
                            <h1 style={{ fontWeight:"bold", color: "white",  fontSize:"32px",width:"110%",marginLeft:"0%",fontFamily:"Montserrat, sens sarif"}}>MORTGAGE
                            SERVICING.</h1>                  
                        </h1>
                       
                       

                        


            <div className="container" style={{paddingTop: "60px", paddingBottom: "60px"}}>
                <ul className="slider controls-bottom-right">
                    <div>
                        <img src="./Content/media/Services_eyecatcher.png" alt="" style={{width:"100%", height:"85%",marginBottom:"30px"}}/>
                    </div>

                </ul>
                
                {/* <hr className="space" /> */}
                <div className="row">
                    <div className="col-lg-6" >
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            We support one of the fastest growing independent loan
                            services companies in the U.S. with component services that
                            enable them to service loans efficiently and responsively.
                        </p>
                        <br />
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            We set a high bar for our own performance, delivering on our
                            promise of accurate and timely support that delights
                            borrowers and fuels our client’s growth.
                        </p>
                        
                    </div>
                    <div className="col-lg-6">
                        <p style={{font:"arial",fontFamily:"Montserrat, sens sarif"}}>
                            Technology is a significant enabler in our operation: BSI
                            ASSET360 provides us real-time visibility in loan status and
                            condition. Libretto, from Bizzy Labs, scours loan files daily and
                            identifies data anomalies that might otherwise contribute to
                            borrower complaints or compliance risk.
                        </p>
                        <br />
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            We love what we do and it reflects in the quality of our service.
                        </p>
                        
                    </div>
                </div>
                {/* <hr className="space" /> */}
                
            </div>
           
        
    <footer className="light">
        <div className="footer-bar">
            <div className="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="Contact">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
   </div>
</div>
            </div>

        );
    }
}
export default Services;