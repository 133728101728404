import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

// import {StyleSheet, Text, ImageBackground, View} from 'react-native';


class CoreValues extends React.Component{
    render(){
        return(
            <div>
            
    <nav className="menu-classic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
        style={{backgroundColor: "#2d4143"}}>
        <div class="container">
            <div className="menu-brand">
                <a href="home">
                    {/* <img className="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img className="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                </a>
            </div>
            <i className="menu-btn"></i>
            <div className="menu-cnt">
            <div className="shoe-container">
                <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="" style={{height:"90px" }}/>
            </a>
        </div>
        <img src={"Content/media/GPTWEntra2024.png"} alt="" style={{height:"300px", position:"relative",right :"-90%",marginTop:"20px"}}/>
            <ul style={{marginLeft:"auto", marginRight:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Employee Testimonial</a></li>
                            <li><a  onClick={(event) => {
          event.preventDefault(); 
          alert('You are about to leave this website and visit bsifinancial services careers page');
          window.location.href = 'https://bsifinancial.com/careers.html'; 
        }} style={{fontSize: "12px"}}>Careers</a></li>
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    
                </ul>
            </div>
        </div>
    </nav>
    {/* <header className="header-base" style={{backgroundImage: ('Content/media/secondary_banner.png')}}>
        <div className="container">
            <h1 style={{maxWidth: "1200px", color: "white"}}>OUR BEHAVIOUR AND PERFORMANCE ARE DRIVEN BY OUR CORE VALUES.
            </h1>

        </div>
    </header> */}


	{/* <div className="gfg">
		<img src="Content/media/secondary_banner.png" />
		<h1 style= {{position: "absolute",top: "200px" ,left: "0", width: "100%"}}>
        OUR BEHAVIOUR AND PERFORMANCE ARE DRIVEN BY OUR CORE VALUES.
		</h1>
	</div> */}
    <div classNameName="header header-base" style={{ width: "100%", zIndex: "-1", position: "absolute" }}>
                    <div>
                        <img src="Content/media/secondary_banner.png" style={{width:"100%", height:"300px"}}/>
                        <h1 className="first-txt" style={{ fontSize:"32px", fontWeight:"bold",color: "white", position: "absolute", top: "170px" ,width:"90%",marginLeft:"9%",fontFamily:"Montserrat, sens sarif"}}>
                        OUR BEHAVIOUR AND PERFORMANCE ARE DRIVEN BY OUR CORE VALUES.
                        </h1>


        
            <div className="container" style={{paddingTop: "60px", paddingBottom: "60px"}}>
                <ul className="slider controls-bottom-right">
                    <li>
                        <img src="Content/media/values.png" alt="" style={{width:"100%",height:"85%"}} />
                    </li>

                </ul>

                {/* <hr className="space" /> */}
                <div className="row">
                    <div className="col-lg-6">
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            We all make promises. When we keep them, it speaks to the
                            quality of our character.
                        </p>
                        <br />
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            At Entra, we honor and practice core values that speak to
                            commitment, honesty, integrity and the effort we apply to our
                            daily tasks.
                        </p>
                        <br />
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            Our clients recognize and appreciate our commitment.
                        </p>
                       
                    </div>
                    <div className="col-lg-6">
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            As a member of the Entra Solutions team, we expect that you
                            will learn, embrace and practice our core values. Our collective
                            commitment honors the integrity we all apply to our effort.

                            
                        </p>
                        <br />
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            
                            Do you have what it takes to be team member bound by a
                            commitment to our core values? Delivering on these values is
                            gratifying, rewarding and career-enhancing.
                        </p>
                        
                    </div>
                </div>
                {/* <hr className="space" /> */}
               
            </div>
        
    
    <footer className="light">
        <div className="footer-bar">
            <div className="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="Contact">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
    </div>
        </div>

            </div>

        );
    }
}
export default CoreValues;