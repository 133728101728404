import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'



class Contact extends React.Component{
    render(){
        return(
            
            <div >
            
    <nav class="menu-classic menu-fixed menu-transparent light align-right" data-menu-anima="fade-in"
        style={{backgroundColor: "#2d4143"}}>
        <div class="container">
            <div class="menu-brand">
                <a href="home">
                    {/* <img class="logo-default scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img class="logo-retina scroll-hide" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img class="logo-default scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" />
                    <img class="logo-retina scroll-show" src="Content/media/logos/ESPL_White_Logo.png" alt="logo" /> */}
                    
                </a>
            </div>
            <i class="menu-btn"></i>
            <div class="menu-cnt">
            <div className="shoe-container">
            <a href ="home">
            <img src={"Content/media/logos/ESPL_White_Logo.png"} alt="logo" style={{height:"90px", marginLeft:"auto", marginRight:"auto"}}/>
            </a>
        </div>
        <img src={"Content/media/GPTWEntra2024.png"} alt="" style={{height:"300px", position:"relative",right :"-90%",marginTop:"20px"}}/>
                <ul style={{marginLeft:"auto", marginRight:"auto"}}>
                    <li>
                        <a href="home#aboutus" style={{fontSize: "12px"}}>About Us</a>
                    </li>
                    <li>
                        <a href="Services" style={{fontSize: "12px"}}>Services</a>
                    </li>
                    <li>
                        <a href="CoreValues" style={{fontSize: "12px"}}>Core Values</a>
                    </li>
                    <li>
                        <a href="home#technology" style={{fontSize: "12px"}}>Technology</a>
                    </li>
                    <li>
                        <a href="home#leadership" style={{fontSize: "12px"}}>Leadership</a>
                    </li>
                    <li className="dropdown">
                    <a href="#" style={{fontSize: "12px"}}>Careers</a>
                        <ul>
                           
                            <li><a href="Test" style={{fontSize: "12px"}}>Employee Testimonial</a></li>
                            <li><a onClick={(event) => {
          event.preventDefault(); 
          alert('You are about to leave this website and visit bsifinancial services careers page');
          window.location.href = 'https://bsifinancial.com/careers.html'; 
        }} style={{fontSize: "12px"}}>Careers</a></li>
                          
                            
                        </ul>
                    </li>
                    <li>
                        <a href="Contact" style={{fontSize: "12px"}}>Contact</a>
                    </li>
                    <li>
                        <a href="LifeAtEspl" style={{fontSize: "12px"}}>Life@ESPL</a>
                    </li>
                    
                </ul>
            </div>
        </div>
    </nav>

    {/* <header class="header-base" style={{backgroundImage: ("Content/media/abc.jpg"),height: "350px"}}>
    

        <div class="container">
            <h1 style={{maxWidth: "1200px",color: "white",marginBottom: "60px"}}>WE ARE HERE TO SERVE YOU.</h1>

           
        </div>
    </header> */}
    {/* <div className="header">
    <img src={"Content/media/abc.jpg"} alt="" />  
  </div> */}
  <div className="header header-base" style={{ width: "100%", zIndex: "-1", position: "absolute" }}>
                    <div>
                        <img src="Content/media/abc.jpg" style= {{width:"100%", height:"300px",marginBottom:"10px"}}/>
                        <h1 class="first-txt" style={{ fontWeight:"bold", color: "white", position: "absolute", fontSize:"32px", top: "140px", left: "20px",width:"90%",marginLeft:"9%" ,fontFamily:"Montserrat, sens sarif"}}>
                        WE ARE HERE TO SERVE YOU.
                        </h1>
 
   <main>

            <div class="container" style={{paddingTop: "60px", paddingBottom: "60px"}}>
                <ul class="slider controls-bottom-right">
                    <li >
                    
                    <a href="https://www.google.com/maps/place/Entra+Solutions+Pvt+Ltd%20/@28.4926474,77.0805255,15z/data=!4m5!3m4!1s0x0:0xf4457cd6bbd4f7a1!8m2!3d28.4925923!4d77.0804745" target="_blank">
                       <img src="Content/media/109map.PNG" 
                        
                          alt="" style={{width:"100%", height:"85%",marginBottom:"50px"}} />
                         </a>
                    </li>

                </ul>
                
                {/* <hr class="space" /> */}
                <div class="row">
                    <div class="col-lg-8">
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                        Plot No: 109, 2nd & 3rd Floor,
                        </p>
                        <p style={{fontFamily:"Montserrat, sens sarif"}}>
                            Udyog Vihar Phase IV, Gurugram 122015
                        </p>
                       
                        
                    </div>
                    <div class="col-lg-8" style={{textAlign:"center"}}>
                        
                    <p style={{marginTop:"-80px",fontFamily:"Montserrat, sens sarif",textAlign:"right"}}>
                            Apply for job <a href="mailto:esplresume@bsifinancial.com">esplresume@bsifinancial.com</a>
                        </p>
                            <p  style={{fontFamily:"Montserrat, sens sarif",textAlign:"right"}}>
                            Other Queries  <a href="mailto:esplcomm@bsifinancial.com">esplcomm@bsifinancial.com</a>
                        </p>

                       
                    </div>
                </div>
                {/* <hr class="space" /> */}
            </div>
        </main>

    <footer className="light">
        <div className="footer-bar">
            <div class="container">
                <span>©2021 Entra Solutions Pvt Ltd | <a href="#">Contact us</a> </span>
                <span></span>
            </div>
        </div>
        
        <link href="Content/themekit/media/icons/iconsmind/line-icons.min.css" rel="stylesheet" />
        <script src="Content/themekit/Scripts/jquery.min.js"></script>
        <script src="Content/themekit/scripts/main.js"></script>
        <script src="Content/themekit/scripts/parallax.min.js"></script>
        <script src="Content/themekit/scripts/glide.min.js"></script>
        <script src="Content/themekit/scripts/custom.js"></script>
        <script src="Content/themekit/scripts/progress.js"></script>
        <script src="Content/themekit/scripts/tab-accordion.js"></script>
        <script src="Content/themekit/scripts/magnific-popup.min.js"></script>
        <script src="Content/themekit/scripts/imagesloaded.min.js"></script>
        <script src="Content/themekit/scripts/contact-form/contact-form.js"></script>
    </footer>
    </div>
        </div>
    
            </div>

        );
    }
}
export default Contact;